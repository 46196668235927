import React, {Component, FunctionComponent, Children, isValidElement, cloneElement} from 'react'
import SectionHeader from './SectionHeader'

type Props = {
    id?: string,
    style?: 'intro'|'normal'
}

const Section: FunctionComponent<Props> = ({children, id, style = 'normal'}) => {
    let classNames: string[] = []
    switch (style) {
        case 'intro':
            classNames.push('intro')
            break
    }

    return <section id={id} className={classNames.join(' ')}>
        <header>
            {
                Children.map(
                    children,
                    (child) => {
                        if (! isValidElement(child) || child.type.prototype !== SectionHeader.prototype) {
                            return null
                        }

                        return cloneElement(child)
                    }
                )
            }
        </header>
        <div className="content">
            {
                Children.map(
                    children,
                    (child) => {
                        if (! isValidElement(child)) {
                            return child
                        }

                        if (child.type.prototype === SectionHeader.prototype) {
                            return null
                        }

                        return child
                    }
                )
            }
        </div>
    </section>
}

export default Section
