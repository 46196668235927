import React, {FunctionComponent} from 'react'
import {useTranslation, Link} from 'gatsby-plugin-react-i18next'
import {Link as ScrollLink} from 'react-scroll'

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Section from '../Section/Section'
import SectionHeader from '../Section/SectionHeader'
import BookButton from '../BookButton'

type IProps = {
    page: string
}

const Header: FunctionComponent<IProps> = ({page}) => {
    const {t} = useTranslation()

    const data = useStaticQuery(graphql`
        query {
          header: file(relativePath: { eq: "images/header03.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          },
          logo: file(relativePath: { eq: "images/logo.png" }) {
            childImageSharp {
              fixed(height: 150) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
    `)

    return <Section style={'intro'}>
        <SectionHeader title={<Img fixed={data.logo.childImageSharp.fixed} alt={t('header.logo')} style={{marginTop: '-4rem'}} />}>
            <p>{t('header.tagline')}</p>
            <ul className="actions">
                {
                    page !== 'index'
                        ? <>
                            <li>
                                <Link to="/">
                                    <span className="label">{t('nav.home')}</span>
                                </Link>
                            </li>
                            <li>|</li>
                        </>
                        : null
                }
                {
                    page !== 'history'
                        ? <>
                            <li>
                                <Link to="/history#content">
                                    <span className="label">{t('nav.history')}</span>
                                </Link>
                            </li>
                            <li>|</li>
                        </>
                        : null
                }
                {
                    page !== 'rooms'
                        ? <>
                            <li>
                                <Link to="/rooms#content">
                                    <span className="label">{t('nav.rooms')}</span>
                                </Link>
                            </li>
                            <li>|</li>
                        </>
                        : null
                }
                {
                    page === 'index'
                        ? <>
                            <li>
                                <ScrollLink to="facilities" smooth={true} duration={500}>
                                    <span className="label">{t('nav.facilities')}</span>
                                </ScrollLink>
                            </li>
                            <li>|</li>
                            <li>
                                <ScrollLink to="gallery" smooth={true} duration={500}>
                                    <span className="label">{t('nav.gallery')}</span>
                                </ScrollLink>
                            </li>
                            <li>|</li>
                            <li>
                                <ScrollLink to="location" smooth={true} duration={500}>
                                    <span className="label">{t('nav.location')}</span>
                                </ScrollLink>
                            </li>
                            <li>|</li>
                            <li>
                                <ScrollLink to="contact" smooth={true} duration={500}>
                                    <span className="label">{t('nav.contact')}</span>
                                </ScrollLink>
                            </li>
                        </>
                        : <>
                            <li>
                                <Link to="/#facilities">
                                    <span className="label">{t('nav.facilities')}</span>
                                </Link>
                            </li>
                            <li>|</li>
                            <li>
                                <Link to="/#location">
                                    <span className="label">{t('nav.location')}</span>
                                </Link>
                            </li>
                            <li>|</li>
                            <li>
                                <Link to="/#contact">
                                    <span className="label">{t('nav.contact')}</span>
                                </Link>
                            </li>
                        </>
                }
            </ul>
            <BookButton />
        </SectionHeader>
        <span className="image fill" data-position="center">
            <img src={data.header.childImageSharp.fluid.src} />
        </span>
    </Section>
}

export default Header
