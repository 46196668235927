import React, {Component, FunctionComponent} from 'react'

type Props = {
    title: string|Component|Element,
    style?: string
}

const SectionHeader: FunctionComponent<Props> = ({children, title, style = 'normal'}) => {
    let TitleTag
    switch (style) {
        case 'intro':
            TitleTag = 'h1'
            break
        default:
            TitleTag = 'h2'
    }

    return <>
        <TitleTag>{title}</TitleTag>
        {children}
    </>
}

export default SectionHeader
