import React, {FunctionComponent} from 'react'
import {Link as ScrollLink, animateScroll} from 'react-scroll'
import {useTranslation, Link} from 'gatsby-plugin-react-i18next'

type IProps = {
    page: string
}

const Footer: FunctionComponent<IProps> = ({page}) => {
    const {t} = useTranslation()

    return <>
        <div className="copyright">
            {
                page !== 'index'
                    ? <>
                        <Link to="/">
                            <span className="label">{t('nav.home')}</span>
                        </Link>
                        &nbsp;|&nbsp;
                    </>
                    : null
            }
            {
                page !== 'history'
                    ? <>
                        <Link to="/history#content">
                            <span className="label">{t('nav.history')}</span>
                        </Link>
                        &nbsp;|&nbsp;
                    </>
                    : null
            }
            {
                page !== 'rooms'
                    ? <>
                        <Link to="/rooms#content">
                            <span className="label">{t('nav.rooms')}</span>
                        </Link>
                        &nbsp;|&nbsp;
                    </>
                    : null
            }
            {
                page === 'index'
                    ? <>
                        <ScrollLink to="facilities" smooth={true} duration={500}>
                            <span className="label">{t('nav.facilities')}</span>
                        </ScrollLink>
                        &nbsp;|&nbsp;
                        <ScrollLink to="gallery" smooth={true} duration={500}>
                            <span className="label">{t('nav.gallery')}</span>
                        </ScrollLink>
                        &nbsp;|&nbsp;
                        <ScrollLink to="contact" smooth={true} duration={500}>
                            <span className="label">{t('nav.contact')}</span>
                        </ScrollLink>
                    </>
                    : <>
                        <Link to="/#facilities">
                            <span className="label">{t('nav.facilities')}</span>
                        </Link>
                        &nbsp;|&nbsp;
                        <Link to="/#contact">
                            <span className="label">{t('nav.contact')}</span>
                        </Link>
                    </>
            }
        </div>
        <div className="copyright" dangerouslySetInnerHTML={{__html: t('footer.copyright')}} />
    </>
}

export default Footer

