import React, {FunctionComponent} from 'react'
import {useTranslation, useI18next} from 'gatsby-plugin-react-i18next'

const BookButton: FunctionComponent = () => {
    const {t, language} = useI18next()

    let languageCode
    switch (language) {
        case 'en':
            languageCode = 'en-GB'
            break
        case 'el':
            languageCode = 'el-GR'
            break
        case 'sv':
            languageCode = 'sv-SE'
            break
        case 'sl':
            languageCode = 'sl-SI'
            break
        case 'zh':
            languageCode = 'zh-CN'
            break
        default:
            languageCode = language.toLowerCase() + '-' + language.toUpperCase()
    }

    return <a
        className={'button primary'}
        href={'https://reservations.cubilis.eu/hotel-orion-gent?Language=' + languageCode}
        target={'_blank'}
        style={{width: 'auto'}}
    >
        {t('book_button')}
    </a>
}

export default BookButton
